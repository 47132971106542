import React, { useEffect, useState, useRef } from 'react';
import { FaPlus, FaMinus, FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import ReactToPrint from 'react-to-print';
import NotificationButton from './NotificationButton';


const ITEMS_PER_PAGE = 10; // Number of products to display per page


const MainPage = () => {
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [counters, setCounters] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [productTypeFilter, setProductTypeFilter] = useState("ALL"); // Filter by product type
  const [productTypes, setProductTypes] = useState([]); // List of product types
  const [notificationData, setNotificationData] = useState();
  const [paymentMethod, setPaymentMethod] = useState(""); // Track the payment method

  // Handle changes in payment method
  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };




  const componentRef = useRef(); // Ref for the printable component



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://wayawaya-db.com/api/get-products");

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);

        // Extract product types
        const types = Array.from(new Set(result.map(item => item.product_type)));
        setProductTypes(types);

        const initialCounters = result.reduce((acc, item, index) => {
          acc[index] = 0;
          return acc;
        }, {});

        setCounters(initialCounters);

        // Check if any product has zero quantity
        const zeroQunData = result.filter(item => item.product_qun === '0');
        setNotificationData(zeroQunData);

      } catch (error) {
        console.log("ERROR FETCHING DATA", error);
      }
    };

    fetchData();
  }, []);

  const updateOrderDetails = (globalIndex, quantity) => {
    const product = paginatedData[globalIndex - startIndex]; // Access the filtered product using globalIndex
    const updatedOrder = [...orderDetails];
    const existingOrderIndex = updatedOrder.findIndex(order => order.idproducts === product.idproducts);

    if (existingOrderIndex > -1) {
      if (quantity > 0) {
        updatedOrder[existingOrderIndex].quantity = quantity;
      } else {
        updatedOrder.splice(existingOrderIndex, 1);
      }
    } else if (quantity > 0) {
      updatedOrder.push({
        idproducts: product.idproducts, // Ensure idproducts is added here
        product_name: product.product_name,
        product_price: parseFloat(product.product_price),
        quantity: quantity,
      });
    }

    setOrderDetails(updatedOrder);
  };

  const increment = (globalIndex) => {
    const product = paginatedData[globalIndex - startIndex];
    if (product) {
      setCounters((prevCounters) => {
        const updatedCounters = {
          ...prevCounters,
          [globalIndex]: (prevCounters[globalIndex] || 0) + 1,
        };
        updateOrderDetails(globalIndex, updatedCounters[globalIndex]); // Use globalIndex
        return updatedCounters;
      });
    }
  };

  const decrement = (globalIndex) => {
    const product = paginatedData[globalIndex - startIndex];
    if (product) {
      setCounters((prevCounters) => {
        const updatedCounters = {
          ...prevCounters,
          [globalIndex]: (prevCounters[globalIndex] || 0) > 0 ? prevCounters[globalIndex] - 1 : 0,
        };
        updateOrderDetails(globalIndex, updatedCounters[globalIndex]); // Use globalIndex
        return updatedCounters;
      });
    }
  };


  // Printer Connection function

  const handlePrint = async () => {
    try {
      // Combine all data into a single string for query parameters
      const currentDateTime = new Date().toLocaleString();
      const queryParams = new URLSearchParams({
        orderDetails: JSON.stringify(orderDetails),
        totalPrice: totalPrice.toFixed(2),
        paymentMethod: paymentMethod || "N/A",
        dateTime: currentDateTime,
      }).toString();
  
      // Send data to Node.js API via GET request
      const response = await fetch(`https://wayawaya-db.com/response?${queryParams}`);
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      console.log("Response from server:", data);
  
      // Redirect to the printing app
      const serverUrl = `https://wayawaya-db.com/response?${queryParams}`;
      window.location.href = `my.bluetoothprint.scheme://${serverUrl}`;
    } catch (error) {
      console.error("Error sending print data:", error);
    }
  };
  



  // const updateOrderDetails = (index, quantity) => {
  //   const updatedOrder = [...orderDetails];
  //   const existingOrderIndex = updatedOrder.findIndex(order => order.product_name === data[index].product_name);

  //   if (existingOrderIndex > -1) {
  //     if (quantity > 0) {
  //       updatedOrder[existingOrderIndex].quantity = quantity;
  //     } else {
  //       updatedOrder.splice(existingOrderIndex, 1);
  //     }
  //   } else if (quantity > 0) {
  //     updatedOrder.push({
  //       product_name: data[index].product_name,
  //       product_price: parseFloat(data[index].product_price),
  //       quantity: quantity,
  //     });
  //   }

  //   setOrderDetails(updatedOrder);
  // };

  // const increment = (index) => {
  //   setCounters((prevCounters) => {
  //     const updatedCounters = {
  //       ...prevCounters,
  //       [index]: prevCounters[index] + 1,
  //     };
  //     updateOrderDetails(index, updatedCounters[index]);
  //     return updatedCounters;
  //   });
  // };

  // const decrement = (index) => {
  //   setCounters((prevCounters) => {
  //     const updatedCounters = {
  //       ...prevCounters,
  //       [index]: prevCounters[index] > 0 ? prevCounters[index] - 1 : 0,
  //     };
  //     updateOrderDetails(index, updatedCounters[index]);
  //     return updatedCounters;
  //   });
  // };

  const handleAddOrder = async () => {
    // Check if any product in the order has zero stock by comparing orderDetails with data
    const outOfStockItems = orderDetails.filter(item => {
      const matchingProduct = data.find(product => product.product_name === item.product_name);
      console.log(matchingProduct && parseInt(matchingProduct.product_qun) === 0)
      return matchingProduct && parseInt(matchingProduct.product_qun) <= 0; // Ensure stock is checked as a number
    });


    if (outOfStockItems.length > 0) {
      // Show the popup message for out-of-stock items
      alert(`The following product(s) are out of stock: ${outOfStockItems.map(item => item.product_name).join(", ")}. You cannot add them to the order.`);
      return; // Stop the order process
    }


    // Check if a payment method is selected
    if (!paymentMethod) {
      alert("Please select a payment method.");
      return;
    }


    const orderData = {
      order: orderDetails,
      order_date: new Date(),
      payment_method: paymentMethod,
    };

    try {
      const response = await fetch("https://wayawaya-db.com/api/add-order", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
      // eslint-disable-next-line
      const data = await response.json();
      alert('Order submitted successfully:');

      // Update product quantities
      for (const item of orderDetails) {
        await fetch("https://wayawaya-db.com/api/update-product-quantity", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            productName: item.product_name,
            quantity: item.quantity,
          }),
        });
      }

      resetOrderPage(); // Reset the order page after successfully adding the order to the database
    } catch (error) {
      alert('Error submitting order:', error);
    }
  };


  // const handleAddOrder = async () => {
  //   const orderData = {
  //     order: orderDetails,
  //     order_date: new Date(),
  //   };


  //   try {
  //     const response = await fetch("https://wayawaya-db.com/api/add-order", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(orderData),
  //     });

  //     const data = await response.json();
  //     alert('Order submitted successfully:');

  //     // Update product quantities
  //     for (const item of orderDetails) {
  //       await fetch("https://wayawaya-db.com/api/update-product-quantity", {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           productName: item.product_name,
  //           quantity: item.quantity,
  //         }),
  //       });
  //     }

  //     resetOrderPage(); // Reset the order page after successfully adding the order to the database
  //   } catch (error) {
  //     alert('Error submitting order:', error);
  //   }
  // };

  const resetOrderPage = () => {
    setCounters(data.reduce((acc, _, index) => {
      acc[index] = 0;
      return acc;
    }, {}));
    setOrderDetails([]);
  };

  // Filter data based on search and product type
  const filteredData = data
    .filter((item) => item.product_name.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter((item) => productTypeFilter === "ALL" || item.product_type === productTypeFilter);

  // Pagination logic
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedData = filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const totalPrice = orderDetails.reduce((acc, item) => acc + (item.product_price * item.quantity), 0);

  return (
    <div>
      <div className>
        <NotificationButton data={notificationData} />
      </div>
      <div className='lg:flex justify-between lg:m-10'>
        <div className="lg:w-2/3 p-8">
          <div className='flex flex-col md:flex-row md:justify-between items-center mb-4 gap-4 md:gap-6'>
            <h2 className="text-2xl font-bold">Products</h2>
            <div className='flex flex-col md:flex-row md:gap-4 w-full md:w-auto'>
              <input
                type="text"
                placeholder="Search for a product..."
                className="p-2 border border-gray-400 rounded-lg w-full md:w-60"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page when search query changes
                }}
              />
              <div className="relative">
                <select
                  value={productTypeFilter}
                  onChange={(e) => {
                    setProductTypeFilter(e.target.value);
                    setCurrentPage(1); // Reset to first page when filter changes
                  }}
                  className="p-2 pl-3 pr-8 border border-gray-400 appearance-none rounded-lg mt-2 md:mt-0 w-full md:w-60 bg-white focus:outline-none"
                >
                  <option value="ALL">All Types</option>
                  {productTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-4 pt-2   lg:pt-1 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-4 gap-4 items-center bg-gray-100 p-4 rounded-t-md'>
            <div className='font-bold text-left'>Product Name</div>
            <div className='font-bold text-center'>Price</div>
            <div className='font-bold text-center'>Current Stock</div>
            <div className='font-bold text-right'>Quantity</div>
          </div>

          {paginatedData.length > 0 ? (
            paginatedData.map((item, index) => {
              const globalIndex = startIndex + index; // Calculate the global index
              //globalindex problem
              return (
                <div
                  key={item.idproducts}  // Use idproducts for the key
                  className='grid grid-cols-4 gap-4 items-center p-1 lg:p-4 border-b'
                >
                  <div
                    onClick={() => increment(globalIndex)} // Pass globalIndex to increment
                    className='cursor-pointer text-left'
                  >
                    {item.product_name}
                  </div>
                  <div className='text-center'>
                    {parseFloat(item.product_price).toFixed(2)} Kč
                  </div>
                  <div className='text-center'>
                    {item.product_qun > 0 ? (
                      <div className='text-green-600'>{item.product_qun}</div>
                    ) : (
                      <div className='text-red-600'>{item.product_qun}</div>
                    )}
                  </div>
                  <div className='flex items-center justify-end lg:gap-12 md:gap-12'>
                    <button
                      className='text-red-600'
                      onClick={() => decrement(globalIndex)} // Pass globalIndex to decrement
                    >
                      <FaMinus className='w-5 h-5' />
                    </button>
                    {/* <input
                      className='text-center border rounded h-8 w-12 pl-3'
                      key={item.idproducts}
                      type='number'
                      value={counters[globalIndex] || 0} // Use globalIndex to track quantity
                      readOnly
                    /> */}
                    <button
                      className='text-green-600'
                      onClick={() => increment(globalIndex)} // Pass globalIndex to increment
                    >
                      <FaPlus className='w-5 h-5' />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-4">No products found</div>
          )}


          {/* {paginatedData.length > 0 ? (
            paginatedData.map((item, index) => {
              const globalIndex = startIndex + index; // Calculate the global index

              return (
                <div
                  key={globalIndex}  // Use globalIndex as the key
                  className='grid grid-cols-4 gap-4 items-center p-1 lg:p-4 border-b'
                >
                  <div
                    onClick={() => increment(globalIndex)} // Pass globalIndex to increment
                    className='cursor-pointer text-left'
                  >
                    {item.product_name}
                  </div>
                  <div className='text-center'>
                    {parseFloat(item.product_price).toFixed(2)} Kč
                  </div>
                  <div className='text-center'>

                    {item.product_qun > 0 ? (
                      <div className='text-green-600'>{item.product_qun}</div>
                    ) : (
                      <div className='text-red-600'>{item.product_qun}</div>
                    )}
                  </div>
                  <div className='flex items-center justify-end lg:gap-2'>
                    <button
                      className='text-red-600'
                      onClick={() => decrement(globalIndex)} // Pass globalIndex to decrement
                    >
                      <FaMinus />
                    </button>
                    <input
                      className='text-center border rounded h-8 w-12 pl-3'
                      type='number'
                      value={counters[globalIndex]} // Access counters using globalIndex
                      readOnly
                    />
                    <button
                      className='text-green-600'
                      onClick={() => increment(globalIndex)} // Pass globalIndex to increment
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-4">No products found</div>
          )} */}

          {/* Pagination Controls */}
          <div className='flex justify-between items-center mt-6'>
            <button
              className='flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50'
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaArrowLeft />
              Previous
            </button>
            <span className='text-gray-700'>
              Page {currentPage} of {totalPages || 1}
            </span>
            <button
              className='flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50'
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || totalPages === 0}
            >
              Next
              <FaArrowRight />
            </button>
          </div>
        </div>

        <div className='w-px h-full bg-gray-300'></div>

        <div className="lg:w-1/3 p-8 mt-16">
          <OrderDetails
            ref={componentRef}
            orderDetails={orderDetails}
            totalPrice={totalPrice}
            paymentMethod={paymentMethod} // Pass paymentMethod as prop
            onPaymentMethodChange={handlePaymentMethodChange} // Pass handler to update paymentMethod
          />
          <div className='flex gap-4'>
            <button
              className='mt-6 px-4 py-2 bg-blue-500 text-white rounded-lg w-full disabled:opacity-50'
              disabled={orderDetails.length === 0}
              onClick={handleAddOrder}
            >
              Confirm
            </button>

            <button
              onClick={handlePrint}
              className="mt-6 px-4 py-2 bg-green-500 text-white rounded-lg w-full disabled:opacity-50"
            >
              Print
            </button>

            <button
              className='mt-6 px-4 py-2 bg-red-500 text-white rounded-lg w-full disabled:opacity-50'
              disabled={orderDetails.length === 0}
              onClick={resetOrderPage}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

    </div>
  );
};

const OrderDetails = React.forwardRef(
  ({ orderDetails, totalPrice, paymentMethod, onPaymentMethodChange }, ref) => {
    const currentDateTime = new Date().toLocaleString();

    return (
      <div ref={ref} className="p-6 border rounded-md shadow-md bg-white">
        <h2 className="text-2xl font-bold mb-2 text-center">Order Details</h2>
        <p className="text-center text-gray-500 mb-4">{currentDateTime}</p>
        {orderDetails.length === 0 ? (
          <p className="text-center">No items ordered yet.</p>
        ) : (
          <ul className="space-y-2">
            {orderDetails.map((item, index) => (
              <li
                key={item.idproducts || index} // Fallback to index if idproducts is not available
                className="flex justify-between py-2 border-b"
              >
                <span>
                  {item.product_name} x {item.quantity}
                </span>
                <span>
                  {(item.product_price * item.quantity).toFixed(2)} Kč
                </span>
              </li>
            ))}
            <li className="flex justify-between py-2 font-bold">
              <span>Total:</span>
              <span>{totalPrice.toFixed(2)} Kč</span>
            </li>
          </ul>
        )}

        <div>
          <div className="mt-4">Select the Payment Method: </div>
          <div className="mt-4">
            <label
              htmlFor="paymentMethod"
              className="block text-sm font-medium text-gray-700"
            >
              Payment Method
            </label>
            <select
              id="paymentMethod"
              value={paymentMethod}
              onChange={(e) => onPaymentMethodChange(e.target.value)} // Use the handler to update paymentMethod
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select a method</option>
              <option value="cash">Cash</option>
              <option value="card">Card</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
);




// const OrderDetails = React.forwardRef(
//   ({ orderDetails, totalPrice, paymentMethod, onPaymentMethodChange }, ref) => {
//     const currentDateTime = new Date().toLocaleString();

//     const handlePrint = () => {
//       if (ref.current) {
//         // Create JSON data dynamically based on the component's content
//         const printData = {};
//         // Header text
//         printData.push({
//           type: 0, // Text
//           content: "Order Details",
//           bold: 1,
//           align: 1, // Center
//           format: 2, // Double Height
//         });

//         // Current date and time
//         const currentDateTime = new Date().toLocaleString();
//         printData.push({
//           type: 0,
//           content: currentDateTime,
//           bold: 0,
//           align: 1, // Center
//           format: 0, // Normal
//         });

//         // Order items
//         orderDetails.forEach((item) => {
//           printData.push({
//             type: 0,
//             content: `${item.product_name} x ${item.quantity} = ${(item.product_price * item.quantity).toFixed(2)} Kč`,
//             bold: 0,
//             align: 0, // Left
//             format: 0, // Normal
//           });
//         });

//         // Total price
//         printData.push({
//           type: 0,
//           content: `Total: ${totalPrice.toFixed(2)} Kč`,
//           bold: 1,
//           align: 2, // Right
//           format: 2, // Double Height
//         });

//         // Send data to PHP script via POST request
//         fetch("https://ahmedtebakhi.com/test.php", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(printData),
//         })
//           .then((response) => {
//             if (!response.ok) {
//               throw new Error("Network response was not ok");
//             }
//             return response.json();
//           })
//           .then((data) => {
//             console.log("Response from server:", data);

//             // Redirect to the printing app
//             const serverUrl = "https://ahmedtebakhi.com/test.php";
//             window.location.href = `bprint://${serverUrl}`;
//           })
//           .catch((error) => {
//             console.error("Error sending print data:", error);
//           });
//       }
//     };



//     return (
//       <div ref={ref} className="p-6 border rounded-md shadow-md bg-white">
//         <h2 className="text-2xl font-bold mb-2 text-center">Order Details</h2>
//         <p className="text-center text-gray-500 mb-4">{currentDateTime}</p>
//         {orderDetails.length === 0 ? (
//           <p className="text-center">No items ordered yet.</p>
//         ) : (
//           <ul className="space-y-2">
//             {orderDetails.map((item, index) => (
//               <li
//                 key={item.idproducts || index} // Fallback to index if idproducts is not available
//                 className="flex justify-between py-2 border-b"
//               >
//                 <span>
//                   {item.product_name} x {item.quantity}
//                 </span>
//                 <span>
//                   {(item.product_price * item.quantity).toFixed(2)} Kč
//                 </span>
//               </li>
//             ))}
//             <li className="flex justify-between py-2 font-bold">
//               <span>Total:</span>
//               <span>{totalPrice.toFixed(2)} Kč</span>
//             </li>
//           </ul>
//         )}

//         <div>
//           <div className="mt-4">Select the Payment Method: </div>
//           <div className="mt-4">
//             <label
//               htmlFor="paymentMethod"
//               className="block text-sm font-medium text-gray-700"
//             >
//               Payment Method
//             </label>
//             <select
//               id="paymentMethod"
//               value={paymentMethod}
//               onChange={(e) => onPaymentMethodChange(e.target.value)} // Use the handler to update paymentMethod
//               className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//             >
//               <option value="">Select a method</option>
//               <option value="cash">Cash</option>
//               <option value="card">Card</option>
//             </select>
//           </div>
//         </div>
//         <div className="mt-4 text-center">
//           {/* Print Button */}
//           <button
//             onClick={handlePrint}
//             className="px-4 py-2 bg-blue-500 text-white rounded"
//           >
//             Print
//           </button>

//           {/* Test Print Button */}
//           <a
//             href="bprint://https://ahmedtebakhi.com/test.php"
//             className="px-4 py-2 bg-gray-500 text-white rounded inline-block ml-2"
//           >
//             Test Print
//           </a>
//           <a href='bprint://http://192.168.0.54:8001/response'>Test the response</a>
//         </div>

//       </div>
//     );
//   }
// );

// const OrderDetails = React.forwardRef(({ orderDetails, totalPrice, paymentMethod, onPaymentMethodChange }, ref) => {
//   const currentDateTime = new Date().toLocaleString();



//   return (
//     <div ref={ref} className="p-6 border rounded-md shadow-md bg-white ">
//       <h2 className='text-2xl font-bold mb-2 text-center'>Order Details</h2>
//       <p className="text-center text-gray-500 mb-4">{currentDateTime}</p>
//       {orderDetails.length === 0 ? (
//         <p className="text-center">No items ordered yet.</p>
//       ) : (
//         <ul className="space-y-2">
//           {orderDetails.map((item, index) => (
//             <li
//               key={item.idproducts || index}  // Fallback to index if idproducts is not available
//               className='flex justify-between py-2 border-b'
//             >
//               <span>
//                 {item.product_name} x {item.quantity}
//               </span>
//               <span>
//                 {(item.product_price * item.quantity).toFixed(2)} Kč
//               </span>
//             </li>
//           ))}
//           <li className='flex justify-between py-2 font-bold'>
//             <span>Total:</span>
//             <span>{totalPrice.toFixed(2)} Kč</span>
//           </li>
//         </ul>
//       )}

//       <div>
//         <div className='mt-4'>Select the Payment Method: </div>
//         {/* Payment method selection */}
//         <div className="mt-4">
//           <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700">Payment Method</label>
//           <select
//             id="paymentMethod"
//             value={paymentMethod}
//             onChange={(e) => onPaymentMethodChange(e.target.value)} // Use the handler to update paymentMethod
//             className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//           >
//             <option value="">Select a method</option>
//             <option value="cash">Cash</option>
//             <option value="card">Card</option>
//           </select>
//         </div>
//       </div>
//     </div>
//   );
// });

export default MainPage;
